import * as React from "react"
import FAQ from "../components/faq/faq"
import Seo from "../components/seo"
import "../assets/css/style.css"
import "../assets/css/layout.css"
import "../assets/css/faq-style.css"
import { graphql } from "gatsby"
import { organizationSchema } from "../const/schemas.pl"
import { languages } from "../const/languages"

const FAQPage = ({ data }) => <FAQ data={data} lang={languages.PL}/>

export const Head = () => {
  return (
    <>
      <Seo
        title="AR-Labs.io | Odpowiedzi na Twoje pytania dotyczące AR w biznesie!"
        description="Rzeczywistość rozszerzona bez tajemnic. Poznaj odpowiedzi na najczęściej zadawane przez naszych klientów pytania dotyczące wirtualnej przymierzalni lub skontaktuj się z nami i zadaj własne!"
        canonicalURL="https://staging-area.ar-labs.io/pl/faq"
      />
      <script type="application/ld+json">{organizationSchema}</script>
    </>
  )
}

export const FAQContentQuery = graphql`
  query FAQPageQuery {
    wpPage(title: { eq: "FAQ PL" }) {
      content
    }
  }
`

export default FAQPage
